import { isPlatformServer } from '@angular/common';
import { computed, effect, inject, PLATFORM_ID, untracked } from '@angular/core';

import { ProductFastSearchItem, ProductFastSearchResult, SearchApi } from '@idealsupply/ngclient-webservice-inventory';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { AuthenticationService } from 'authentication-data';

import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, of, Subscription } from 'rxjs';
import { RequestStatus, withSearchResults } from 'state-data';

export interface ProductQuickSearchFilter {
  keyword: string;
}

export const ProductQuickSearchByPartNumberStore = signalStore(
  { providedIn: 'root' },
  withSearchResults({ keyword: '' }, (store) => {
    const searchAPI = inject(SearchApi);
    return (value, _, limit) => searchAPI.fastSearchProductsByPartNumber(value.keyword, limit);
  }),
);

export const ProductQuickSearchDescriptionStore = signalStore(
  { providedIn: 'root' },
  withSearchResults({ keyword: '' }, (store) => {
    const searchAPI = inject(SearchApi);
    return (value, _, limit) => {
      return searchAPI.fastSearchProductsByDescription(value.keyword, limit);
    };
  }),
);

export const ProductQuickSearchByCrossReferenceStore = signalStore(
  { providedIn: 'root' },
  withState({ stockLocations: [] }),
  withComputed(() => {
    const auth = inject(AuthenticationService);
    return {
      enabled: computed(() => {
        const customerNumbers = auth.user()?.customerNumbers.filter((v) => v !== '000002') ?? [];
        return customerNumbers.length > 0;
      }),
    };
  }),
  withSearchResults({ keyword: '' }, (store) => {
    const searchApi = inject(SearchApi);
    const authService = inject(AuthenticationService);
    return (value, _, limit) => {
      const customerNumbers = authService.user()?.customerNumbers.filter((v) => v !== '000002') ?? [];
      return customerNumbers.length
        ? searchApi.fastSearchProductsByCrossReference(value.keyword, limit)
        : of({
            limit: 0,
            skip: 0,
            more: false,
            data: [],
          } as ProductFastSearchResult);
    };
  }),
);

export const ProductQuickSearchByKeywordStore = signalStore(
  { providedIn: 'root' },
  withSearchResults({ keyword: '' }, (store) => {
    const searchApi = inject(SearchApi);
    return (value, _, limit) => searchApi.fastSearchProductsByKeyword(value.keyword, limit);
  }),
);

export interface ProductQuickSearchStoreConfig {
  partNumberEnabled: boolean;
  descriptionEnabled: boolean;
  keywordEnabled: boolean;
  crossReferenceEnabled: boolean;
  filter: string;
  numResults: number;
}
export interface ProductFastSearchResults {
  byPartNumber: ProductQuickSearchRecord[];
  byDescription: ProductQuickSearchRecord[];
  byKeyword: ProductQuickSearchRecord[];
  byCrossReference: ProductQuickSearchRecord[];
}

export interface ProductQuickSearchRecord extends ProductFastSearchItem {
  type: 'partNumber' | 'description' | 'keyword' | 'crossReference';
  score: number;
}

function scoreItem(item: ProductFastSearchItem, filter: string): number {
  if (item.matches?.map((v) => v.toUpperCase()).includes(filter.toUpperCase())) {
    return Number.MAX_SAFE_INTEGER;
  }
  return item.matches?.length ?? 0;
}

function toProductQuickSearchRecord(
  type: 'partNumber' | 'description' | 'keyword' | 'crossReference',
  filter: string,
): (item: ProductFastSearchItem | null) => ProductQuickSearchRecord | null {
  return (item: ProductFastSearchItem | null) => {
    return !item
      ? null
      : {
          ...item,
          type,
          score: scoreItem(item, filter),
        };
  };
}

function toProductQuickSearchRecords(
  type: 'partNumber' | 'description' | 'keyword' | 'crossReference',
  filter: string,
  results: (ProductFastSearchItem | null)[],
) {
  return results.map(toProductQuickSearchRecord(type, filter)).sort((a, b) => {
    const scoreA = a?.score ?? 0;
    const scoreB = b?.score ?? 0;
    const deltaScore = scoreB - scoreA;
    if (deltaScore !== 0) {
      return deltaScore;
    }
    return (a?.description ?? '').localeCompare(b?.description ?? '');
  });
}

export const ProductQuickSearchStore = signalStore(
  { providedIn: 'root' },
  withState<ProductQuickSearchStoreConfig>({
    partNumberEnabled: true,
    descriptionEnabled: true,
    keywordEnabled: true,
    crossReferenceEnabled: true,
    filter: '',
    numResults: 10,
  }),
  withComputed((store) => {
    const byPartNumber = inject(ProductQuickSearchByPartNumberStore);
    const byDescription = inject(ProductQuickSearchDescriptionStore);
    const byKeyword = inject(ProductQuickSearchByKeywordStore);
    const byCrossReference = inject(ProductQuickSearchByCrossReferenceStore);

    const search = computed(() => {
      return {
        byPartNumber: toProductQuickSearchRecords('partNumber', untracked(store.filter), byPartNumber.search()?.results ?? []),
        byDescription: toProductQuickSearchRecords('description', untracked(store.filter), byDescription.search()?.results ?? []),
        byKeyword: toProductQuickSearchRecords('keyword', untracked(store.filter), byKeyword.search()?.results ?? []),
        byCrossReference: toProductQuickSearchRecords('crossReference', untracked(store.filter), byCrossReference.search()?.results ?? []),
      } as ProductFastSearchResults;
    });

    const loading = computed(() => {
      const result = {
        loading: false,
        byPartNumber: byPartNumber.searchStatus() === RequestStatus.INITIALIZING || byPartNumber.searchStatus() === RequestStatus.FETCHING,
        byDescription:
          byDescription.searchStatus() === RequestStatus.INITIALIZING || byDescription.searchStatus() === RequestStatus.FETCHING,
        byKeyword: byKeyword.searchStatus() === RequestStatus.INITIALIZING || byKeyword.searchStatus() === RequestStatus.FETCHING,
        byCrossReference:
          byCrossReference.searchStatus() === RequestStatus.INITIALIZING || byCrossReference.searchStatus() === RequestStatus.FETCHING,
      };
      result.loading = result.byPartNumber || result.byCrossReference || result.byDescription || result.byKeyword;
      return result;
    });
    const state = computed(() => {
      const result = {
        loading: false,
        byPartNumber: byPartNumber.searchStatus() === RequestStatus.INITIALIZING || byPartNumber.searchStatus() === RequestStatus.FETCHING,
        byDescription:
          byDescription.searchStatus() === RequestStatus.INITIALIZING || byDescription.searchStatus() === RequestStatus.FETCHING,
        byKeyword: byKeyword.searchStatus() === RequestStatus.INITIALIZING || byKeyword.searchStatus() === RequestStatus.FETCHING,
        byCrossReference:
          byCrossReference.searchStatus() === RequestStatus.INITIALIZING || byCrossReference.searchStatus() === RequestStatus.FETCHING,
      };
      result.loading = result.byPartNumber || result.byCrossReference || result.byDescription || result.byKeyword;
      return result;
    });

    const hasCrossReferences = computed(() => byCrossReference.enabled());

    return {
      search,
      loading,
      hasCrossReferences,
    };
  }),
  withMethods((store) => {
    const isServer = isPlatformServer(inject(PLATFORM_ID));
    const byPartNumber = inject(ProductQuickSearchByPartNumberStore);
    const byDescription = inject(ProductQuickSearchDescriptionStore);
    const byKeyword = inject(ProductQuickSearchByKeywordStore);
    const byCrossReference = inject(ProductQuickSearchByCrossReferenceStore);

    const load = (keyword: string, limit: number) => {
      if (isServer) {
        return;
      }
      byPartNumber.setSearchFilter({ keyword });
      byDescription.setSearchFilter({ keyword });
      byKeyword.setSearchFilter({ keyword });
      byCrossReference.setSearchFilter({ keyword });

      if (untracked(store.partNumberEnabled)) {
        byPartNumber.loadSearchResults(limit);
      }
      if (untracked(store.descriptionEnabled)) {
        byDescription.loadSearchResults(limit);
      }
      if (untracked(store.keywordEnabled)) {
        byKeyword.loadSearchResults(limit);
      }
      if (untracked(store.crossReferenceEnabled)) {
        byCrossReference.loadSearchResults(limit);
      }
    };

    var setFilter = (filter: string) => patchState(store, { filter });
    var setMaxResults = (numResults: number) => patchState(store, { numResults });

    return {
      load,
      setFilter,
      setMaxResults,
    };
  }),
  withHooks((store) => {
    const isServer = isPlatformServer(inject(PLATFORM_ID));
    if (isServer) {
      return {};
    }
    const filter$$ = new BehaviorSubject<string>(store.filter());
    let sub: Subscription;
    return {
      onInit() {
        effect(
          () => {
            filter$$.next(store.filter());
          },
          { allowSignalWrites: true },
        );
        effect(
          () => {
            // store.load(untracked(store.filter), store.numResults());
          },
          { allowSignalWrites: true },
        );
        sub = filter$$
          .pipe(
            debounceTime(500),
            map((filter) => filter.trim()),
            filter((filter) => !!filter),
            distinctUntilChanged(),
          )
          .subscribe((value) => store.load(value, store.numResults()));
      },
      onDestroy() {
        sub?.unsubscribe();
      },
    };
  }),
);
