import { inject, isDevMode, Provider, signal } from '@angular/core';
import { NavItemType, provideNavItems } from 'navigation-data';
import { PRODUCTS_ROUTE_PATH } from 'products-data';
import { defaultProductPromotionsFeatureConfig, ProductsFeatureConfig } from './ProductsFeatureConfig';
import { provideProductsFeatureRoutes } from './products-feature.routes';

export function provideProductsFeature(config?: Partial<ProductsFeatureConfig>): Provider {
  const useConfig = { ...defaultProductPromotionsFeatureConfig, ...config };
  return [
    provideProductsFeatureRoutes(config?.routes),
    provideNavItems({
      type: NavItemType.RouteLink,
      id: 'app.main-menu/products2',
      label: signal($localize`:@@menu.products.label:Products 2`),
      icon: signal('inventory_2'),
      command: () => signal(['/', inject(PRODUCTS_ROUTE_PATH)]),
      hidden: signal(!isDevMode()),
    }),
    provideNavItems({
      type: NavItemType.RouteLink,
      id: 'app.main-menu/products/details',
      label: signal($localize`:@@menu.products.label:Products 2`),
      icon: signal('inventory_2'),
      command: () => signal(['/', inject(PRODUCTS_ROUTE_PATH)]),
      hidden: signal(!isDevMode()),
    }),
  ];
}
